import React from "react"

const ArrowGreen = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="643.857"
        height="369.535"
        viewBox="0 0 643.857 369.535"
      >
        <g data-name="Group 3291" transform="rotate(180 605.178 1493.017)">
          <g data-name="Group 3289" transform="translate(413.681 2482.283)">
            <path
              d="M730.241 288.671V168.508s-7.488-29.791-33.663-29.791c-36 0-507.762-1-507.762-1s-32.5 5.545-32.5 35.333v293.29c0 9.632 9.159 33.912 32.239 33.912h511.027c12.788 0 30.939-14.317 30.939-30.039v-146.1s-.424-15.656 14.46-15.656h46.8"
              className="prefix__cls-1"
              id="pathGreen"
              data-name="Path 2774"
            ></path>
            <path
              d="M766.5 281.76l26.676 26.855-25.888 25.885"
              className="prefix__cls-1"
              id="endGreen"
              data-name="Path 2775"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default ArrowGreen
